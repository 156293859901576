import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import SettingsActions from 'store/reducers/Settings';
import cancellationPoliciesService from 'api/CancellationPolicies';
import CancellationPoliciesView from 'views/Property/RoomType/Accommodation/Edit/CancellationPolicies/Table';
import CancellationPoliciesEdit from 'views/Property/RoomType/Accommodation/Edit/CancellationPolicies/Edit';
import { formatDataToSend, handlePromise } from 'utils';
import _get from 'lodash/get';
import EditDrawer from '../EditDrawer';

const CancellationPolicies = ( {
  data,
  entityId,
  paths,
  parentIds,
  toggleLoading,
  toggleInfoAlert,
  onReloadData,
} ) => {
  const [editModalOpened, setEditModalOpened] = useState( false );
  const [editDrawerOpened, setEditDrawerOpened] = useState( false );
  const [cancellationPolicy, setCancellationPolicy] = useState( [] );

  const deletePolicy = ( id, cd ) => {
    cancellationPolicy.splice( id, 1 );
    setCancellationPolicy(
      cancellationPolicy.map( ( item, index ) => ( { ...item, id: index } ) ),
    );
    cd();
  };

  const addPolicy = ( policy ) => {
    cancellationPolicy.push( { ...policy, id: cancellationPolicy.length } );
    setEditModalOpened( false );
  };
  const reloadData = useCallback( () => onReloadData(), [onReloadData] );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const dataToSend = formatDataToSend( formData, form );
      const formattedData = {
        policyText: dataToSend.policyText,
        cancellationPolicies: cancellationPolicy.map( ( cp ) => {
          const { id, ...rest } = cp;
          return {
            ...rest,
          };
        } ),
      };

      const propertyId = parentIds
        ? parentIds.property
        : formattedData.property;
      const roomTypeId = parentIds
        ? parentIds.roomType
        : formattedData.roomType;

      const [errors, response] = await handlePromise(
        _get( data, 'entity.cancellationPolicies.id' )
          ? cancellationPoliciesService.updateCancellationPolicies(
            propertyId,
            roomTypeId,
            entityId,
            formattedData,
          )
          : cancellationPoliciesService.saveCancellationPolicies(
            propertyId,
            roomTypeId,
            entityId,
            formattedData,
          ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      await reloadData();

      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [
      toggleInfoAlert,
      toggleLoading,
      data,
      entityId,
      parentIds,
      cancellationPolicy,
      reloadData,
    ],
  );

  useEffect( () => {
    if ( data.entity ) {
      setCancellationPolicy(
        _get( data, 'entity.cancellationPolicies.cancellationPolicy', [] ).map(
          ( item, index ) => ( { ...item, id: index } ),
        ),
      );
    }
  }, [data.entity, data] );

  const openDrawer = () => setEditDrawerOpened( true );
  const closeDrawer = () => setEditDrawerOpened( false );

  return (
    <>
      <CancellationPoliciesView
        name={data.entity ? data.entity.name : ''}
        data={_get( data, 'entity.cancellationPolicies', {} )}
        paths={paths}
        onSubmit={submitForm}
        onDelete={deletePolicy}
        cancellationPolicy={cancellationPolicy}
        setCancellationPolicy={setCancellationPolicy}
        toggleEditModal={openDrawer}
      />

      <EditDrawer
        data={{ ..._get( data, 'entity.cancellationPolicies', {} ), cancellationPolicy }}
        onOpenModal={() => setEditModalOpened( true )}
        entityId={entityId}
        open={editDrawerOpened}
        onClose={closeDrawer}
        onReloadOccupancies={reloadData}
        cancellationPolicy={cancellationPolicy}
        onDelete={deletePolicy}
        onSubmit={submitForm}
      />

      <CancellationPoliciesEdit
        open={editModalOpened}
        toggleEditModal={setEditModalOpened}
        onSubmit={addPolicy}
      />
    </>
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( CancellationPolicies );
