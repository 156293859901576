import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import LandlordView from 'views/Property/RoomType/Accommodation/Edit/Landlord/Form/index';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import _get from 'lodash/get';
import LandlordsService from 'api/AccommodationLandlords';

const Landlord = ( {
  data, entityId, paths,
  toggleLoading, toggleInfoAlert, onReloadData,
} ) => {
  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const dataToSend = formatDataToSend( formData, form );
      const formattedData = { ...dataToSend, accommodations: [entityId] };
      const [errors, response] = await handlePromise(
        _get( data, 'entity.landlord.id' )
          ? LandlordsService.updateAccomodationLandlord(
            data.entity.landlord.id, formattedData,
          )
          : LandlordsService.saveAccomodationLandlord(
            formattedData,
          )
        ,
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }
      onReloadData();
      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [
      toggleInfoAlert, toggleLoading, onReloadData, data, entityId,
    ],
  );

  return (
    <LandlordView
      name={data.entity ? data.entity.name : ''}
      data={_get( data, 'entity', {} )}
      paths={paths}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( Landlord );
