import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import EditDrawerView from 'views/Property/RoomType/Accommodation/Edit/CancellationPolicies/EditDrawer';

const EditDrawer = ( {
  data,
  cancellationPolicy,
  open,
  onClose,
  onReloadRates,
  onOpenModal,
  onDelete,
  onSubmit,
} ) => (
  <EditDrawerView
    data={data}
    open={open}
    toggleEditModal={onOpenModal}
    cancellationPolicy={cancellationPolicy}
    onClose={onClose}
    onReloadRates={onReloadRates}
    onDelete={onDelete}
    onSubmit={onSubmit}
  />
);

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( null, mapDispatchToProps )( EditDrawer );
