import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = ( propertyId, roomTypeId ) => `/properties/${propertyId}/room-types/${roomTypeId}/accommodations`;

export default {

  saveRUInfo( propertyId, roomTypeId, id, data ) {
    return api.post( `${baseEndpoint( propertyId, roomTypeId )}/${id}/ru-info`, data );
  },
  updateRUInfo( propertyId, roomTypeId, id, data ) {
    return api.put( `${baseEndpoint( propertyId, roomTypeId )}/${id}/ru-info`, data );
  },

  updateRUImages( propertyid, roomTypeId, id, data ) {
    return api.post( `${baseEndpoint( propertyid, roomTypeId )}/${id}/ru-images`, formatFormDataBody( data ) );
  },

};
