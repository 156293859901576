/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import {
  TextField, ModalForm, Datepicker, Select, Intl,
} from 'components';
import { required } from 'config/InputErrors';

export default React.memo(
  ( {
    bookingId,
    isLoading,
    data,
    open,
    onClose,
    onSubmit,
    onDelete,
    zIndex = 9999,
  } ) => (
    <ModalForm
      title={bookingId ? 'editBookingBlocked' : 'newBookingBlocked'}
      customTitle={(
        <>
          <Intl id={bookingId ? 'editBookingBlocked' : 'newBookingBlocked'} />
          {bookingId
            ? (
              <button
                type="button"
                className="ml-5 btn btn-primary"
                onClick={() => onDelete( bookingId )}
              >
                <i className="fas fa-trash text-lg" />
              </button>
            ) : null}
        </>
)}
      showErrorsInFooter
      isLoading={isLoading}
      open={open}
      size="xl"
      bodyClassName="overflow-visible"
      bodyStyle={{ minHeight: 330 }}
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={data}
      zIndex={zIndex}
      body={( ) => (
        <>
          <Row>
            <Col md={4}>
              <Datepicker
                field="from"
                label="from"
                dateFormat="DD/MM/YYYY"
                utc
                labelClass="font-weight-bold"
                validate={required}
                startDateField={{ value: moment().subtract( '1', 'day' ) }}
              />
            </Col>
            <Col md={4}>
              <Datepicker
                field="till"
                label="till"
                dateFormat="DD/MM/YYYY"
                utc
                labelClass="font-weight-bold"
                validate={required}
                startDateField={{ value: moment().subtract( '1', 'day' ) }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Select
                field="category"
                label="reason"
                translateOptions
                validate={required}
                options={[
                  {
                    name: 'works',
                    id: 'works',
                  },
                  {
                    name: 'bed_bugs',
                    id: 'bed_bugs',
                  },
                  {
                    name: 'opening',
                    id: 'opening',
                  },
                  {
                    name: 'overbooking',
                    id: 'overbooking',
                  },
                  {
                    name: 'relocation',
                    id: 'relocation',

                  },
                  {
                    name: 'owner_needs',
                    id: 'owner_needs',
                  },
                  {
                    name: 'others',
                    id: 'others',
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                field="description"
                label="description"
                type="textarea"
                labelClass="font-weight-bold"
                rows={3}
                validate={required}
              />
            </Col>
          </Row>
        </>
      )}
    />
  ),
);
