import React from 'react';
import RatesDashboardView from 'views/Property/RoomType/Accommodation/Edit/Rates/Dashboard';

const RatesDashboard = ( {
  entityId, data, parentIds, onReloadData,
} ) => (
  <RatesDashboardView
    name={data.entity ? data.entity.name : ''}
    data={data}
    entityId={entityId}
    parentIds={parentIds}
    onReloadData={onReloadData}
  />
);

export default RatesDashboard;
