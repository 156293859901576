import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import SettingsActions from 'store/reducers/Settings';
import checkInOutService from 'api/CheckInOut';
import CheckInOutView from 'views/Property/RoomType/Accommodation/Edit/CheckInOut/Form';
import { formatDataToSend, handlePromise } from 'utils';
import _get from 'lodash/get';

const CheckInOut = ( {
  data, entityId, paths, parentIds,
  toggleLoading, toggleInfoAlert,
} ) => {
  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const dataToSend = formatDataToSend( formData, form );
      const formattedData = {
        checkInFrom: dataToSend.checkInFrom ? dataToSend.checkInFrom.format( 'HH:mm' ) : dataToSend.checkInFrom,
        checkInTo: dataToSend.checkInTo ? dataToSend.checkInTo.format( 'HH:mm' ) : dataToSend.checkInTo,
        checkOutUntil: dataToSend.checkOutUntil ? dataToSend.checkOutUntil.format( 'HH:mm' ) : dataToSend.checkOutUntil,
        daysBeforeArrival: dataToSend.daysBeforeArrival,
        place: dataToSend.place,
      };

      const propertyId = parentIds ? parentIds.property : formattedData.property;
      const roomTypeId = parentIds ? parentIds.roomType : formattedData.roomType;

      const [errors, response] = await handlePromise(
        _get( data, 'entity.checkInOut.id' )
          ? checkInOutService.updateCheckInOut(
            propertyId, roomTypeId, entityId, formattedData,
          )
          : checkInOutService.saveCheckInOut(
            propertyId, roomTypeId, entityId, formattedData,
          )
        ,
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [
      toggleInfoAlert, toggleLoading, data, entityId, parentIds,
    ],
  );

  return (
    <CheckInOutView
      name={data.entity ? data.entity.name : ''}
      data={_get( data, 'entity.checkInOut', {} )}
      paths={paths}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( CheckInOut );
