import React, { useState } from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import {
  Form,
  TextField,
  Intl,
  FormActionsBtns,
  CanAccess,
  Button,
} from 'components';
import { canModify } from 'config/Ability';
import { initializeDocument } from '../utils';

export default React.memo( ( { data, paths, onSubmit } ) => {
  const [show, setShow] = useState( false );

  const toggleShow = () => setShow( !show );
  return (
    <>
      <h3 className="m-0">
        <Row>
          <Col>
            <Intl id="arrivalInstructions" />
          </Col>
          <Col className="d-flex flex-row-reverse">
            <Button
              size="md"
              title={!show ? 'ru.settings.show' : 'ru.settings.hide'}
              onClick={toggleShow}
            />
          </Col>
        </Row>
      </h3>
      <hr />
      {show ? (
        <Form
          initialValues={{
            documentCheckInInstruction: initializeDocument( data?.entity ),
            checkInInstructions: data.entity?.checkInInstructions,
          }}
          onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
          showErrorsInFooter
          body={( form ) => (
            <>
              <Row className="mt-3">
                <Col md={8}>
                  <TextField
                    field="checkInInstructions"
                    label="checkInInstructions"
                    type="textarea"
                    rows={5}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={8}>
                  <TextField
                    label={_.get(
                      form.getState(),
                      'values.documentCheckInInstruction.name',
                      'documentCheckInInstruction',
                    )}
                    type="file"
                    onChange={async ( val, e ) => {
                      const mainFile = e.currentTarget.files[0];
                      if ( mainFile ) {
                        form.change( 'documentCheckInInstruction', mainFile );
                      }
                    }}
                    // field="documentCheckInInstruction"
                    labelClass="custom-file-label"
                    inputClass="custom-file-input"
                    formGroupClass="custom-file"
                    accept="application/pdf"
                  />
                  <span className="mt-1 help-text">
                    <Intl id="accommodationImage.help" />
                  </span>
                </Col>
              </Row>
            </>
          )}
          footer={(
            <CanAccess I="edit" a="accommodation">
              <FormActionsBtns cancelTo={paths.accommodation} />
            </CanAccess>
          )}
        />
      ) : null}
    </>
  );
} );
