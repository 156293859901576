/* eslint-disable no-nested-ternary */
import React from 'react';
import RatesTable from 'containers/Property/RoomType/Accommodation/Edit/Rates/Table';
import DiscountTable from 'containers/Property/RoomType/Accommodation/Edit/Discounts/Table';
import OccupancyTable from 'containers/Property/RoomType/Accommodation/Edit/Occupancies/Table';
import PoliciesTable from 'containers/Property/RoomType/Accommodation/Edit/CancellationPolicies/Table';
import { EditCard, Intl } from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    name, entityId, data, parentIds, onReloadData,
  } ) => (
    <EditCard cardClass="card-sec-bottom">
      <h3 className="mb-1">
        <Intl
          id={
            canModify( 'accommodation' ) ? 'editAccommodation' : 'accommodation'
          }
        />
        {` ${name}`}
      </h3>
      <hr className="mt-3 mb-4" />
      <OccupancyTable entityId={entityId} />
      <RatesTable entityId={entityId} />
      <DiscountTable entityId={entityId} />
      <PoliciesTable
        data={data}
        entityId={entityId}
        parentIds={parentIds}
        onReloadData={onReloadData}
      />
    </EditCard>
  ),
);
