import React from 'react';
import SettingsActions from 'store/reducers/Settings';
import { connect } from 'react-redux';
import RUInfoView from 'views/Property/RoomType/Accommodation/Edit/RUInfo';

const RUInfo = ( {
  data, entityId, paths, parentIds, onReloadData,
} ) => (
  <RUInfoView
    name={data.entity ? data.entity.name : ''}
    data={data}
    entityId={entityId}
    paths={paths}
    parentIds={parentIds}
    onReloadData={onReloadData}
  />
);

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( RUInfo );
