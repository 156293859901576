import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import {
  Form,
  TextField,
  FormActionsBtns,
  Intl,
  CanAccess,
  Datepicker,
  Button,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';

export default React.memo( ( { data, paths, onSubmit } ) => {
  const [show, setShow] = useState( false );

  const toggleShow = () => setShow( !show );

  return (
    <>
      <h3 className="m-0">
        <Row>
          <Col>
            <Intl id="checkInOut" />
          </Col>
          <Col className="d-flex flex-row-reverse">
            <Button
              size="md"
              title={!show ? 'ru.settings.show' : 'ru.settings.hide'}
              onClick={toggleShow}
            />
          </Col>
        </Row>
      </h3>
      <hr />
      {show ? (
        <Form
          initialValues={data}
          onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
          showErrorsInFooter
          body={() => (
            <>
              <Row>
                <Col md={6}>
                  <Datepicker
                    field="checkInFrom"
                    label="checkInOut:checkInFrom"
                    validate={required}
                    dateFormat={false}
                    timeFormat="HH:mm"
                    utc
                  />
                </Col>
                <Col md={6}>
                  <Datepicker
                    field="checkInTo"
                    label="checkInOut:checkInTo"
                    validate={required}
                    dateFormat={false}
                    timeFormat="HH:mm"
                    utc
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Datepicker
                    field="checkOutUntil"
                    label="checkInOut:checkOutUntil"
                    validate={required}
                    dateFormat={false}
                    timeFormat="HH:mm"
                    utc
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    field="daysBeforeArrival"
                    label="checkInOut:daysBeforeArrival"
                    type="number"
                    validate={required}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <TextField
                    field="place"
                    label="checkInOut:place"
                    type="textarea"
                    validate={required}
                  />
                </Col>
              </Row>
            </>
          )}
          footer={(
            <CanAccess I="edit" a="accommodation">
              <FormActionsBtns cancelTo={paths.accommodation} />
            </CanAccess>
          )}
        />
      ) : null}
    </>
  );
} );
