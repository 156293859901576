import React from 'react';

import {
  Intl, LocalTable, Button,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( { cancellationPolicy, toggleEditModal } ) => (
    <>
      <h3>
        <Intl id="cancellationPolicies" />
      </h3>
      <LocalTable
        data={cancellationPolicy}
        isLoading={false}
        rightContent={
          canModify( 'accommodation' ) && (
            <Button
              size="md"
              color="primary"
              onClick={toggleEditModal}
              title="edit"
            />
          )
        }
        containerClassName="card-sec-bottom"
        columns={[
          {
            Header: 'cancellationPolicies:validFrom',
            accessor: 'validFrom',
          },
          {
            Header: 'cancellationPolicies:validTo',
            accessor: 'validTo',
          },
          {
            Header: 'cancellationPolicies:percentage',
            accessor: 'percentage',
          },
        ]}
      />
    </>
  ),
);
