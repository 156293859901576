const formatPlugins = ( pluginsData ) => {
  const pluginsFiltered = pluginsData.filter( ( plugin ) => plugin.categories.find( ( cat ) => cat === 'SML' ) );
  return pluginsFiltered.map( ( plugin ) => ( {
    id: plugin.key,
    value: plugin.key,
    name: plugin.name,
  } ) );
};

const formatConfiguredPlugins = ( cfgdPliginsData ) => {
  if ( cfgdPliginsData ) {
    return [];
  }
  return cfgdPliginsData.data;
};

const setCustomFields = ( provider ) => {
  if ( provider === 'Akiles' ) {
    return [
      {
        type: 'accommodation',
        dataType: 'text',
        name: 'lockerId',
        displayName: 'Locker Id',
        required: true,
        category: 'smartlockers',
        allowFilter: false,
        options: [],
      },
      {
        type: 'accommodation',
        dataType: 'text',
        name: 'lockerSettingId',
        displayName: 'Setting ID',
        required: true,
        category: 'smartlockers',
        allowFilter: false,
        options: [],
      },
    ];
  }
  return [
    {
      type: 'accommodation',
      dataType: 'text',
      name: 'lockerId',
      displayName: 'Locker Id',
      required: true,
      category: 'smartlockers',
      allowFilter: false,
      options: [],
    },
  ];
};

export default { formatPlugins, formatConfiguredPlugins, setCustomFields };
