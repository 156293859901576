import React, { useState } from 'react';
import { canModify } from 'config/Ability';
import { Col, Row } from 'reactstrap';
import { required } from 'config/InputErrors';
import {
  Form,
  TextField,
  FormActionsBtns,
  Intl,
  CanAccess,
  ImagesDropzoneGeneric,
  Button,
} from 'components';

export default React.memo( ( {
  data, paths, images, removeImage, onSubmit,
} ) => {
  const [show, setShow] = useState( false );

  const toggleShow = () => setShow( !show );

  return (
    <>
      <h3 className="m-0">
        <Row>
          <Col>
            <Intl id="ruInfo" />
          </Col>
          <Col className="d-flex flex-row-reverse">
            <Button
              size="md"
              title={!show ? 'ru.settings.show' : 'ru.settings.hide'}
              onClick={toggleShow}
            />
          </Col>
        </Row>
      </h3>
      <hr />
      {show ? (
        <Form
          initialValues={data}
          onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
          showErrorsInFooter
          body={( form ) => (
            <>
              <Row>
                <Col md={8}>
                  <TextField
                    field="description"
                    label="description"
                    type="textarea"
                    validate={required}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 className="m0">
                    <Intl id="ruInfo:images" />
                  </h3>
                </Col>
              </Row>
              {data && images.length > 0 && (
                <Row className="mx-1">
                  {images.map( ( image ) => (
                    <div key={image.id} className="flex m-1">
                      <div
                        style={{ position: 'relative', borderRadius: '10px' }}
                      >
                        <img
                          src={image.url}
                          alt={`RU info ${image.id}`}
                          width="250px"
                          height="185px"
                          className="object-cover"
                          style={{ borderRadius: '10px' }}
                        />
                        <div
                          className="top-2 right-2"
                          style={{
                            display: 'flex',
                            position: 'absolute',
                            width: '28px',
                            height: '28px',
                          }}
                        >
                          <button
                            type="button"
                            className="rounded-circle text-white bg-transparent hover:bg-white hover:text-black transition duration-300"
                            aria-label={`Remove image ${image.id}`}
                            style={{
                              width: '28px',
                              height: '28px',
                              textAlign: 'center',
                              border: '2px solid white',
                            }}
                            onClick={() => removeImage( image.id )}
                          >
                            <i className="th-icon-cross text-sm" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) )}
                </Row>
              )}

              <Row>
                <Col md={6}>
                  <ImagesDropzoneGeneric form={form} />
                </Col>
              </Row>
            </>
          )}
          footer={(
            <CanAccess I="edit" a="accommodation">
              <FormActionsBtns cancelTo={paths.accommodation} />
            </CanAccess>
          )}
        />
      ) : null}
    </>
  );
} );
