import React from 'react';
// import DashboardView from 'views/Property/RoomType/Accommodation/Edit/Discounts/Dashboard';
import { Calendar, AddBtn, EditCard } from 'components';
import classnames from 'classnames';
import { Modal, Row } from 'reactstrap';

const BookingCalendarView = ( {
  data,
  onOpenEditItemModal,
  handleDatesSet,
  onOpenNewModal,
  isLoading,
  onOpenBlockedModal,
  modalOpen,
  toggleModal,
} ) => (
  <Modal
    isOpen={modalOpen}
    toggle={toggleModal}
    className="modal-dialog-centered"
    size="lg"
  >
    <div className={classnames( { 'app-component-loading': isLoading }, 'modal-body' )}>
      <EditCard cardClass="card-sec-bottom">
        <Row className="justify-content-end">
          <div className="pt-1 pr-2">
            <AddBtn title="newBooking" onClick={onOpenNewModal} />
          </div>
          <div className="pt-1 pr-2">
            <AddBtn title="newBookingBlocked" onClick={onOpenBlockedModal} />
          </div>
        </Row>
        <Calendar
          events={data}
          onOpenEditItemModal={onOpenEditItemModal}
          handleDatesSet={handleDatesSet}
          onOpenModal={onOpenNewModal}
          onOpenBlockedModal={onOpenBlockedModal}
        />
      </EditCard>
    </div>
  </Modal>
);

export default BookingCalendarView;
