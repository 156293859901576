import React, { useCallback, useEffect, useState } from 'react';
import { formatDataToSend, handlePromise } from 'utils';
import ruInfoService from 'api/RUInfo';
import SettingsActions from 'store/reducers/Settings';
import RUInfoView from 'views/Property/RoomType/Accommodation/Edit/RUInfo/Form/indes';
import { connect } from 'react-redux';
import _get from 'lodash/get';

const RUInfo = ( {
  data, entityId, paths, parentIds,
  toggleLoading, toggleInfoAlert, toggleErrorAlert, onReloadData,
} ) => {
  const [images, setImages] = useState( [] );

  const removeImage = ( id ) => {
    images.splice( id, 1 );
    setImages( images
      .map( ( item, index ) => ( { ...item, id: index } ) ) );
  };

  const minResolutionValidator = ( file ) => new Promise( ( resolve, reject ) => {
    const img = new Image();
    img.src = URL.createObjectURL( file );
    img.onload = () => {
      const { width, height } = img;
      URL.revokeObjectURL( img.src );
      if ( width >= 1024 && height >= 768 ) {
        resolve( true );
      } else {
        reject( new Error( 'Image resolution is too low.' ) );
      }
    };
    img.onerror = () => {
      reject( new Error( 'Failed to load image.' ) );
    };
  } );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const dataToSend = formatDataToSend( formData, form );
      const { images: files } = formData;
      const formattedData = {
        description: dataToSend.description,
        image: images.length > 0 ? images.map( ( img ) => {
          const { url } = img;
          return url;
        } ) : undefined,
      };

      const propertyId = parentIds.property;
      const roomTypeId = parentIds.roomType;

      const minImagesValidator = files ? images.length + files.length < 10 : images.length < 10;

      if ( minImagesValidator ) {
        toggleLoading( false );
        return toggleErrorAlert( 'ruInfo:minImagesValidation' );
      }

      try {
        if ( files ) {
          await files.reduce( async ( previousPromise, file ) => {
            await previousPromise;
            await minResolutionValidator( file.file );
          }, Promise.resolve() );
        }
      } catch ( error ) {
        toggleLoading( false );
        return toggleErrorAlert( 'ruInfo:minResolutionValidation' );
      }

      const [errors, response] = await handlePromise(
        _get( data, 'entity.RUInfo.id' )
          ? ruInfoService.updateRUInfo(
            propertyId, roomTypeId, entityId, formattedData,
          )
          : ruInfoService.saveRUInfo(
            propertyId, roomTypeId, entityId, formattedData,
          ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      if ( files?.length > 0 ) {
        const uploadImagesSequentially = async () => files.reduce( async ( prevPromise, img ) => {
          await prevPromise;
          const [imageErrors, imageResponse] = await handlePromise(
            ruInfoService.updateRUImages( propertyId, roomTypeId, entityId, {
              image: img.file,
            } ),
          );

          if ( !imageResponse.ok ) {
            throw imageErrors;
          }
        }, Promise.resolve() );

        try {
          await uploadImagesSequentially();
        } catch ( imageErrors ) {
          toggleLoading( false );
          return [imageErrors];
        }
      }

      onReloadData();
      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [
      toggleInfoAlert, toggleLoading, entityId, data, parentIds,
      images, toggleErrorAlert, onReloadData,
    ],
  );

  useEffect( () => {
    if ( data.entity && data.entity.RUInfo && data.entity.RUInfo.image?.length > 0 ) {
      setImages(
        _get( data, 'entity.RUInfo.image', [] )
          .map( ( item, index ) => ( { url: item, id: index } ) ),
      );
    }
  }, [data.entity, data] );

  return (
    <RUInfoView
      name={data.entity ? data.entity.name : ''}
      data={_get( data, 'entity.RUInfo', {} )}
      paths={paths}
      images={images}
      removeImage={removeImage}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( RUInfo );
